<template>
	<BlanketOverlayWithPopup title="Upload GSelector CSV" width="400" :hastabs="false" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="contained-popup-content">
				<table>
					<tbody>
						<tr>
							<td>
								<br/>
								<UploadFile @file-changed="file_changed" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="uploadFileAndProcess">Upload</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import UploadFile from "@/components/UploadFile.vue";
import axios from "axios";

export default {
	name: "HooksBrowseListUpload",
	mixins: [globalMixin],
	components: {
		UploadFile,
		BlanketOverlayWithPopup,
	},
	props: {
		chosenstationname: String,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			uploadfile: null,
		}
	},
	computed: {

	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		file_changed( value ) {
			this.uploadfile = value
		},
		uploadFileAndProcess() {
			if (this.isFetching === false) {
				if(!this.uploadfile) {
					alert("Please select a CSV file.")
					return false
				}
				let self = this
				let form_data = new FormData()
				form_data.append('uid', this.store.user.id)
				form_data.append('wlsesstoken', this.store.wlsesstoken)
				form_data.append('station', JSON.stringify(this.chosenstationname))
				if(this.uploadfile) {
					form_data.append('uploadfile', this.uploadfile)
				}
				this.store.showLoader = true
				this.isFetching = true
				axios({
					url: this.store.urlroot + "central/post-vue/hooks/upload-gselector-csv",
					method: 'POST',
					data: form_data
				}, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(function (response) {
					self.store.showLoader = false
					self.isFetching = false
					let ret = response.data //response.data is returned info
					if(ret.error) {
						self.showKalertError(ret.error);
					}
					else {
						self.$emit('close', ret)
						self.uploadfile = null
					}
				}.bind(this)).catch(function (error) {
					console.log(error)
				});
			}
		},
	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
.contained-popup-content {
	height: 100px;
	min-height: unset;
}
</style>