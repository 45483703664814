<template>
	<p>
		{{hookob.artist}} - {{hookob.title}} [HID: {{hookob.id}}]
		<span class="playbtn" @click.stop="$emit('play')">Play</span>
		<input type="text" v-model="mediaIdEntered" style="margin-left: 10px;" />
		<span class="acceptbtn" @click.stop="$emit('save', mediaIdEntered)">Save</span>
	</p>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: 'HookAssignLine',
	mixins: [globalMixin],
	props: {
		hookob: {
			type: Object,
			default: null,
		},
	},
	emits: ['save','play'],
	data() {
		return {
			store: store,
			mediaIdEntered: '',
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
.playbtn, .acceptbtn {
	display: inline-block;
	padding: 5px 10px;
	background: #2BAEF6;
	color: #FFF;
	cursor: pointer;
	margin: 5px 0 5px 10px;
	transition: background 0.3s ease-in-out;
}
.playbtn:hover, .acceptbtn:hover {
	background: #1B8EEF;
}
</style>
