<template>
	<BlanketOverlayWithPopup title="Media ID Mappings" width="1000" :hastabs="false" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="contained-popup-content">
				<br/>
				<p class="bold subheading">Add Media ID to hooks</p>
				<br/>
				<HookAssignLine v-for="hookob in missing" :key="hookob.id" :hookob="hookob"
					@save="saveMapping(hookob.id, $event)" @play="play(hookob.id)"
				></HookAssignLine>
			</div>
		</template>
		<template v-slot:buttons>
			<div style="display: flex; align-items: center">
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
				<audio src="" controls="" id="player" style="margin-left: 30px;"></audio>
			</div>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import HookAssignLine from "@/components/HookAssignLine.vue";

export default {
	name: "HookMapToMediaIdManual",
	mixins: [globalMixin],
	components: {
		HookAssignLine,
		BlanketOverlayWithPopup,
	},
	props: {
		chosenstationname: String,
		missinghooks: Array,
	},
	emits: ['updatehook'],
	data: function () {
		return {
			store: store,
			isFetching: false,
			completedHookIds: [],
		}
	},
	computed: {
		missing() {
			return this.missinghooks.filter(item => !this.completedHookIds.includes(item.id))
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		play(hookId) {
			let a = document.getElementById('player')
			a.src = "https://yourwavelength.com/hooklib/" + hookId + ".mp3"
			a.play()
		},
		saveMapping(hookId, mediaId) {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/hooks/assign-media-map"
			let sendob = {
				hookId: hookId,
				mediaId: mediaId,
				station: this.chosenstationname
			}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				this.completedHookIds.push(hookId)
				this.$emit('updatehook', [hookId, mediaId])
			}.bind(this))
		},
	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
.contained-popup-content {
	line-height: 1.2;
}
.subheading {
	font-size: 16px;
}
</style>