<template>
	<BlanketOverlayWithPopup title="Manage Default Hooks (when multiple per Media ID)" width="1000" :hastabs="false" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="contained-popup-content">
				<br/>
				<div v-if="dupesWithoutDefault.length > 0" style="margin-bottom: 30px;">
					<p class="bold subheading" style="margin-bottom: 10px;">The following Media IDs have multiple hooks but no Default</p>
					<div v-for="group in dupesWithoutDefault" :key="group[0].mediaId" style="margin-bottom: 10px;">
						<p class="bold" style="color: #0f75a5;">Media ID: {{group[0].mediaId}}</p>
						<div v-for="hook in group" :key="hook.hookId">
							<p>
								{{hook.hookId}}: {{getHookLabel(hook.hookId)}}
								<span class="playbtn" @click.stop="play(hook.hookId)">Play</span>
								<span class="acceptbtn" @click.stop="setDefault(hook.hookId, hook.mediaId)">Set Default</span>
								<span class="unlinkbtn" @click.stop="unsetLink(hook.hookId)">Unlink</span>
							</p>
						</div>
					</div>
				</div>
				<div v-if="dupesWithDefault.length > 0">
					<p class="bold subheading" style="margin-bottom: 10px;">The following Media IDs have defaults set</p>
					<div v-for="group in dupesWithDefault" :key="group[0].mediaId" style="margin-bottom: 10px;">
						<p class="bold" style="color: #0f75a5;">Media ID: {{group[0].mediaId}}</p>
						<div v-for="hook in group" :key="hook.hookId">
							<p>
								<span v-if="hook.isDefault === 1" class="bold">DEFAULT: </span>
								<span :class="{bold : hook.isDefault === 1}">{{hook.hookId}}: {{getHookLabel(hook.hookId)}}</span>
								<span class="playbtn" @click.stop="play(hook.hookId)">Play</span>
								<span class="acceptbtn" @click.stop="setDefault(hook.hookId, hook.mediaId)">Set Default</span>
								<span class="unlinkbtn" @click.stop="unsetLink(hook.hookId)">Unlink</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<div style="display: flex; align-items: center">
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
				<audio src="" controls="" id="player" style="margin-left: 30px;"></audio>
			</div>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "ManageDefaultIds",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		chosenstationname: String,
		hooklib: Array,
	},
	emits: ['updatehook'],
	data: function () {
		return {
			store: store,
			isFetching: false,
			completedHookIds: [],

			dupeGroups: [],
			dupesWithDefault: [],
			dupesWithoutDefault: [],
		}
	},
	computed: {

	},
	methods: {
		getHookLabel(hookId) {
			let hookob = this.hooklib.find(item => item.id === hookId)
			if(hookob) return hookob.artist + ' - ' + hookob.title + ' (' + hookob.year + ')'
			return ''
		},
		closeCancel() {
			this.$emit('close');
		},
		play(hookId) {
			let a = document.getElementById('player')
			a.src = "https://yourwavelength.com/hooklib/" + hookId + ".mp3"
			a.play()
		},
		getDuplicates() {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/hooks/get-mediaid-dupes"
				let sendob = {
					station: this.chosenstationname
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					this.dupeGroups = []
					this.dupesWithDefault = []
					this.dupesWithoutDefault = []
					if(ret.dupes) {
						this.dupeGroups = ret.dupes
						let def = []
						let noDef = []
						for(let arr of this.dupeGroups) {
							let hasDefault = false
							for(let item of arr) {
								if(item.isDefault === 1) {
									hasDefault = true
									break
								}
							}
							if(hasDefault) def.push(arr)
							else noDef.push(arr)
						}
						this.dupesWithDefault = def
						this.dupesWithoutDefault = noDef
					}
				}.bind(this))
		},
		unsetLink(hookId) {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/hooks/assign-media-map"
			let sendob = {
				hookId: hookId,
				mediaId: '',
				station: this.chosenstationname
			}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				this.$emit('updatehook', [hookId, ''])
				this.getDuplicates()
			}.bind(this))
		},
		setDefault(hookId, mediaId) {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/hooks/assign-default-for-mediaid"
			let sendob = {
				hookId: hookId,
				mediaId: mediaId,
				station: this.chosenstationname
			}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				this.$emit('updatehook', [hookId, mediaId])
				this.getDuplicates()
			}.bind(this))
		},
	},
	watch: {

	},
	mounted() {
		this.getDuplicates()
	},
}
</script>

<style scoped>
.contained-popup-content {
	line-height: 1.2;
}
.subheading {
	font-size: 16px;
}
.playbtn, .acceptbtn, .unlinkbtn {
	display: inline-block;
	padding: 3px 10px;
	background: #2BAEF6;
	color: #FFF;
	cursor: pointer;
	margin: 2px 0 2px 10px;
	transition: background 0.3s ease-in-out;
}
.playbtn:hover, .acceptbtn:hover {
	background: #1B8EEF;
}
.unlinkbtn {
	background: #A00;
}
</style>