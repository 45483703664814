<template>
	<div v-if="store.sessionOk">
		<BlanketOverlayWithPopup v-if="showDemoCreator" title="Create New Demographic"
			width="800" :hastabs="false" v-on:close="showDemoCreator = false"
		>
			<template v-slot:main>
				<DemoCreator :survob="activeSurvOb" v-on:closesave="finishSaveDemo($event)"></DemoCreator>
			</template>
		</BlanketOverlayWithPopup>

		<QuestionAssignPopup v-if="showAssignQsPopup" :srvobin="activeSurvOb"
			v-on:close="showAssignQsPopup = false"
		></QuestionAssignPopup>

		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<TopMenuBar>
					<TopMenuGroup v-if="store.user.perms.panelUser !== 1" label="Reports" :hasdropdown="true">
						<!-- music reports from old dash_music-->
						<TopMenuItem v-if="isMusicSurvey === true && store.user.superUser === 1" label="Analyst CSV" @click="report('analyst', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="isMusicSurvey === true && store.user.superUser === 1 || store.user.id === 27" label="Song List" @click="report('songlist', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="isMusicSurvey === true && store.user.superUser === 1 || store.user.id === 27" label="For MDs" @click="report('formds', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="isMusicSurvey === true && store.user.superUser === 1" label="Playlist File" @click="report('playlist', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="isMusicSurvey === true" label="Score Summary" @click="report('summ_scores', chosenSurveyId)"></TopMenuItem>
						<!-- reports from dashboard -->
						<TopMenuItem v-if="store.user.superUser === 1" label="Panel Responses" @click="report('panel', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1" label="Potential Winners" @click="report('winners', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem label="Percep Summary" @click="createCsv"></TopMenuItem>
<!--						<TopMenuItem v-if="store.user.superUser === 1" label="Percep Multi" @click="percepMulti"></TopMenuItem>-->
						<TopMenuItem v-if="store.user.superUser === 1" label="Flat Excel File" @click="report('flatfile', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1" label="Code Keys" @click="report('codekeys', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 || store.user.id === 27" label="Sample Splits (Bauer)" @click="bauerSample"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 && weightingIsAvailableForSurvey === true" label="Weighting Report" @click="runWeightReport"></TopMenuItem>
						<TopMenuItem label="Text Responses" @click="report('textresp', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="analystReportId" label="Song Summary" @click="getAnalystSummary"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1" label="GSelector CSV" @click="getGselCSV"></TopMenuItem>
						<TopMenuItem v-if="needShowCatiReports === true" label="Coded Flatfile" @click="report('codedfile', chosenSurveyId)"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 && (isRegRatingsSurvey === true || this.activeSurvOb.isOnlineRR === 1)" label="Validate Gold Std" @click="showGSValidator = true"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 && isRegRatingsSurvey === true" label="Gold Standard Export" @click="getGoldStd"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 && isRegRatingsSurvey === true" label="Gold Standard JSONL" @click="getGoldStdJsonl"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 && isRegRatingsSurvey === true" label="R&F JSONL" @click="getRFJsonl"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 && isRegRatingsSurvey === true" label="RR Comments" @click="genCommentsExport"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1 && isRegRatingsSurvey === true" label="Audology JSON" @click="audologyExport"></TopMenuItem>
					</TopMenuGroup>
					<TopMenuGroup v-if="store.user.perms.panelUser !== 1" label="Options" :hasdropdown="true">
						<TopMenuItem label="Create Demo" @click="demoCreate"></TopMenuItem>
						<TopMenuItem v-if="pageViewType === 'standard'" label="Single Q View" @click="toggleView('singlequestion')"></TopMenuItem>
						<TopMenuItem v-if="pageViewType === 'singlequestion'" label="Standard View" @click="toggleView('standard')"></TopMenuItem>
						<TopMenuItem v-if="store.user.superUser === 1" label="Assign Qs" @click="showAssignQs"></TopMenuItem>
						<TopMenuItem v-if="hiderawvals === false" label="Hide Raw Values" @click="hiderawvals = true"></TopMenuItem>
						<TopMenuItem v-if="hiderawvals === true" label="Show Raw Values" @click="hiderawvals = false"></TopMenuItem>
					</TopMenuGroup>
					<TopMenuGroup v-if="activeSurvOb && (store.user.superUser === 1 || ['Perceptual','RegRatings','CATI'].includes(activeSurvOb.type))"
						label="Toggle Filters" @click="toggleFilterView" title="Toggle the popup filter creator view"
					></TopMenuGroup>
				</TopMenuBar>
				<div id="maincontent" class="">
					<div class="selections-section">
						<div class="selections-group">
							<div>
								<select class="wl-input-select wl-bg-white" v-model="chosenSurveyId">
									<option v-for="us in userSurveyList" :key="us.id" :value="us.id">{{us.name}}</option>
								</select>
							</div>
							<div>
								<select class="wl-input-select wl-bg-white" v-model="chosenDemoId">
									<option v-for="us in demoList" :key="us.id" :value="us.id">{{us.name}}</option>
								</select>
							</div>
							<div>
								<select class="wl-input-select wl-bg-white narrow" v-model="chosenCompletesChoice">
									<option value="all">Show All</option>
									<option value="only">Completes only</option>
								</select>
							</div>
							<div>
								<select class="wl-input-select wl-bg-white narrow" v-model="chosenWeightingChoice">
									<option v-for="(w, ind) in weightingOptions" :key="ind" :value="w.value">{{w.text}}</option>
								</select>
							</div>
						</div>
						<div class="completion-info">
							<p>
								n=<span v-if="completionData">{{completionDataForDemo.nTotalStarts}}, ({{completionDataForDemo.nCompletes}} complete - {{completionDataForDemo.pctCompletes}}%)
								({{completionDataForDemo.nTotalStarts}} records, {{completionDataForDemo.nDQs}} DQs)
								<span v-if="completionDataForDemo.avgCompletionText && completionDataForDemo.avgCompletionText.length > 0">Median Time {{completionDataForDemo.avgCompletionText}}.</span>
								<span v-if="completionDataForDemo.demoWeightPctOfTotal" style="margin-left: 5px;">Pct of Total: {{completionDataForDemo.demoWeightPctOfTotal}}%.</span>
								<span v-if="completionDataForDemo.demoWeightPctOfUnfilteredDemo" style="margin-left: 5px;">Filtered Pct of Main Demo: {{completionDataForDemo.demoWeightPctOfUnfilteredDemo}}%</span>
							</span>
							</p>
						</div>
					</div>
				</div>
				<div v-if="showTabBar" class="tabdiv">
					<div class="tab" :class="{active : activetab === 'perceptuals'}" @click="activetab = 'perceptuals'">Perceptuals</div>
					<div v-if="showMusicTabs" class="tab" :class="{active : activetab === 'music'}" @click="activetab = 'music'">Music</div>
					<div v-if="showMusicTabs" class="tab" :class="{active : activetab === 'musicHistory'}" @click="activetab = 'musicHistory'">Music History</div>
					<div v-if="showMusicTabs" class="tab" :class="{active : activetab === 'musicHistoryChart'}" @click="activetab = 'musicHistoryChart'">History Chart</div>
					<div v-if="showMusicTabs && activeSurvOb && activeSurvOb.type === 'OMT'" class="tab" :class="{active : activetab === 'musicEras'}" @click="activetab = 'musicEras'">Era Breakdown</div>
					<div v-if="showMusicTabs && showBauerMusicHistory" class="tab" :class="{active : activetab === 'bauerHistory'}" @click="activetab = 'bauerHistory'">Bauer History</div>
				</div>
				<div class="results-section" id="excel-basic-wrapper">

					<ValidatorPage v-if="showGSValidator === true"
						:srvob="activeSurvOb" v-on:close="closeValidationView"
						:fromdashboard="true"
					></ValidatorPage>

					<BlanketOverlayWithPopup v-if="activetab === 'perceptuals' && showMatrixGridPopup" :title="qgrouptitle"
						width="800" :hastabs="false" v-on:close="showMatrixGridPopup = false"
					>
						<template v-slot:main>
							<table class="altbl">
								<thead>
									<tr>
										<td></td>
										<td v-for="(opt, ind) in qgroupopts" :key="ind" class="headingtext">{{opt.opttext}}</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="qres in qgroupdata" :key="qres.qid">
										<td>{{getMatrixSplitCase(qres)}}</td>
										<td v-for="(opt, ind) in qgroupopts" :key="ind" class="ctr">{{getMatrixVal(qres, opt.optid)}}</td>
									</tr>
								</tbody>
							</table>
						</template>
					</BlanketOverlayWithPopup>

					<div v-if="activetab === 'perceptuals' && showFilterPopup === true" class="filters-holder">
						<div class="filter-topbar">
							<p class="filter-h1">Filters</p>
							<button @click="getDashboardData(false)" type="button">Update</button>
							<i class="mdi mdi-close" @click="showFilterPopup = false"></i>
						</div>
						<div class="filter-inside">
							<div class="toggle-option">
								<span class="toggle-option-span" style="display: flex; align-items: center;" title="By default percentages are shown of only those who answered the question within the demographic. Select Total Demographic to show percentage of all in demographic/filters.  Select Total Survey for percentage of all survey completes.">
									<span style="margin-right: 5px; font-weight: bold;">Percent Base</span>
									<select v-model="percentSampleBase">
										<option value="answered">Question Answers</option>
										<option value="alldemo">Total Demographic/Filtered</option>
										<option value="totalsurvey">Total Survey</option>
									</select>
								</span>
							</div>
							<div class="toggle-option" style="margin-top: 10px;">
								<span class="toggle-option-span" style="display: flex; align-items: center;">
									<span style="margin-right: 5px; font-weight: bold;">Weighting</span>
									<select v-model="chosenWeightingChoice">
										<option v-for="(w, ind) in weightingOptions" :key="ind" :value="w.value">{{w.text}}</option>
									</select>
								</span>
							</div>
							<div style="margin-top: 6px;">
								<label>
									<span style="margin-right: 5px; font-weight: bold;">Hide Raw Values</span>
									<input type="checkbox" v-model="hiderawvals">
								</label>
							</div>
							<div v-if="[22639,22454].includes(chosenSurveyId)" style="margin-top: 6px;">
								<label>
									<span style="margin-right: 5px; font-weight: bold;">Radio Listeners Only</span>
									<input type="checkbox" v-model="filterInfDialRadioListenersOnly">
								</label>
							</div>

							<div v-for="(f, ind) in filterDemos" :key="ind" class="filter-item">
								<p class="filter-heading">
									{{f.qob.qtext_stripped}}
									<i class="mdi mdi-close" @click="removeFilter(ind)"></i>
								</p>
								<div v-if="f.isExplicitAge && f.isExplicitAge === true" class="filter-options" style="display:flex; align-items: center;">
									<span>Min </span>
									<input type="number" v-model="f.selections[0]" class="wl-input-text wl-bg-white" style="width: 50px;">
									<span>Max </span>
									<input type="number" v-model="f.selections[1]" class="wl-input-text wl-bg-white" style="width: 50px;">
								</div>
								<div v-else class="filter-options">
									<div v-for="opt in f.qob.options" :key="opt.optid" class="filter-option">
										<label><input type="checkbox" v-model="f.selections" :value="opt.optid"><span>{{opt.opttext}}</span></label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="activetab === 'perceptuals' && resultsDataForDemo.length > 0 && pageViewType === 'singlequestion'">
						<div style="display: flex; align-items: center;">
							<div class="qnavbtn" style="margin-right: 5px;" @click="navigateSingleQuestion(-1)"><i class="mdi mdi-chevron-left"></i></div>
							<div style="width: 315px">
								<Autocompleter v-if="activeSurvOb.questions && activeQuestionSingle" :searchlist="activeSurvOb.questions"
									idfldname="qid" textfldname="qtext_stripped" :incomingval="activeQuestionSingle.qid"
									v-on:update="setSingleViewActiveQuestion($event)"
								></Autocompleter>
							</div>
							<div class="qnavbtn" @click="navigateSingleQuestion(+1)"><i class="mdi mdi-chevron-right"></i></div>
						</div>

						<div v-if="activeQuestionSingle">
							<DashPerceptualSingle
								:resultsdata="resultsDataForDemo" :surveyob="activeSurvOb" :demoid="chosenDemoId"
								:weighted="chosenWeightingChoice" :question="activeQuestionSingle"
								:qindex="activeSurvOb.questions.findIndex(item => item.qid === activeQuestionSingle.qid)"
								:isexporting="isExportingToExcel" :totalsampleoverridepct="percentSampleBase"
								:hiderawvals="hiderawvals" :trenddata="trendDataForQ" :trendsurvs="trendSurveys"
								:forcehidetrend="hideTrendedDataPush"
								v-on:filterby="addFilterWithQuestion($event)" v-on:gridtable="showMatrixGrid($event)"
								v-on:gettrenddata="getTrendData($event)"
							></DashPerceptualSingle>
						</div>
					</div>
					<DashPerceptuals v-if="activetab === 'perceptuals' && resultsDataForDemo.length > 0 && pageViewType === 'standard'"
						:resultsdata="resultsDataForDemo" :surveyob="activeSurvOb" :demoid="chosenDemoId"
						:weighted="chosenWeightingChoice" :isexporting="isExportingToExcel"
						:totalsampleoverridepct="percentSampleBase" :hiderawvals="hiderawvals"
						:trenddata="trendDataForQ" :trendsurvs="trendSurveys" :forcehidetrend="hideTrendedDataPush"
						v-on:filterby="addFilterWithQuestion($event)" v-on:gridtable="showMatrixGrid($event)"
						v-on:gettrenddata="getTrendData($event)"
					></DashPerceptuals>
					<DashMusicTable v-if="activetab === 'music' && hookResults.length > 0" :results="hookResults" v-on:hookhistory="showHookHistory($event)"></DashMusicTable>
					<DashMusicHistoryTable v-if="activetab === 'musicHistory' && hookHistoryResults.length > 0" :results="hookHistoryResults" :surveys="hookHistorySurveys" v-on:hookhistory="showHookHistory($event)"></DashMusicHistoryTable>
					<DashMusicHistoryChart v-if="activetab === 'musicHistoryChart' && hookHistoryResults.length > 0" :results="hookHistoryResults" :surveys="hookHistorySurveys" :incominghookid="chosenHookIdForHistoryChart"></DashMusicHistoryChart>
					<DashBauerHistoryTable v-if="activetab === 'bauerHistory' && hookResults.length > 0" :demoid="chosenDemoId" :weighting="chosenWeightingChoice" :survob="activeSurvOb"></DashBauerHistoryTable>
					<div v-if="activetab === 'musicEras'">
						<p>Top <input type="number" v-model="musicErasTopN" style="margin-left: 5px; border: 1px solid #DDD" /> </p>
						<table class="eratable">
							<tr v-for="(m, ind) in musicEraResults" :key="ind">
								<td>
									<span class="boldme" style="width: 100px;">{{m.era}}</span>
								</td>
								<td>
									<span>{{m.pct}}%</span>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import TopMenuBar from "@/components/TopMenuBar";
import DashPerceptuals from "@/components/dashboard/DashPerceptuals.vue";
import TopMenuGroup from "@/components/TopMenuGroup.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import DemoCreator from "@/components/dashboard/DemoCreator.vue";
import TableToExcel from "@linways/table-to-excel";
import DashMusicTable from "@/components/dashboard/DashMusicTable.vue";
import DashMusicHistoryTable from "@/components/dashboard/DashMusicHistoryTable.vue";
import DashMusicHistoryChart from "@/components/dashboard/DashMusicHistoryChart.vue";
import DashBauerHistoryTable from "@/components/dashboard/DashBauerHistoryTable.vue";
import QuestionAssignPopup from "@/components/home/QuestionAssignPopup.vue";
import Autocompleter from "@/components/Autocompleter.vue";
import DashPerceptualSingle from "@/components/dashboard/DashPerceptualSingle.vue";
import ValidatorPage from "@/components/regional-ratings-admin/ValidatorPage.vue";

export default {
	name: "DashboardPage",
	mixins: [globalMixin],
	components: {
		ValidatorPage,
		DashPerceptualSingle,
		Autocompleter,
		QuestionAssignPopup,
		DashBauerHistoryTable,
		DashMusicHistoryChart,
		DashMusicHistoryTable,
		DashMusicTable,
		DemoCreator,
		BlanketOverlayWithPopup,
		TopMenuItem,
		TopMenuGroup,
		DashPerceptuals,
		TopMenuBar,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			chosenSurveyId: null,
			chosenDemoId: 0,
			chosenCompletesChoice: 'only',
			chosenWeightingChoice: true,
			activetab: 'perceptuals',
			percentSampleBase: 'answered', //answered, alldemo, totalsurvey
			hiderawvals: false,
			pageViewType: 'standard', //singlequestion, standard
			activeQuestionSingle: null,

			weightingIsAvailableForSurvey: true,

			userSurveyList: [],
			demoListRaw: [],

			activeSurvOb: null,
			results: [],
			completionData: null,
			hookResultsWeighted: [],
			hookResultsUnweighted: [],

			hookHistoryResults: [],
			hookHistorySurveys: [],

			showDemoCreator: false,
			isExportingToExcel: false,

			chosenHookIdForHistoryChart: null,
			showAssignQsPopup: false,

			filterDemos: [], //{qid: 1 or null, qtrendkey: string or null, selections: []}
			// newQAddedToFilters: false,
			showFilterPopup: false,
			defaultFiltersForSurvey: [],

			activeQgroupkey: null,
			showMatrixGridPopup: false,

			musicErasTopN: 200,
			musicEraResults: [],

			showGSValidator: false,

			trendSurveys: [],
			trendDataForQ: {},
			hideTrendedDataPush: false,

			filterInfDialRadioListenersOnly: false,

		}
	},
	computed: {
		weightingOptions() {
			let opts = []
			if(this.weightingIsAvailableForSurvey === true) {
				opts.push({value: true, text: 'Weighted'})
			}
			opts.push({value: false, text: 'Unweighted'})
			return opts
		},
		demoList() {
			let dems = [
				{id: 0, name: 'Total'}
			]
			return dems.concat(this.demoListRaw)
		},
		completionDataForDemo() {
			if(this.completionData === null) return null
			let ret = this.completionData.find(item => item.demoId === this.chosenDemoId)
			if(ret) return ret
			return []
		},
		resultsDataForDemo() {
			if(this.results.length === 0) return []
			let ret = this.results.find(item => item.demoId === this.chosenDemoId)
			if(ret) return ret.data
			return []
		},
		showMusicTabs() {
			if(this.activeSurvOb) {
				if(this.store.user.perms.panelUser === 1) return false
				if (this.activeSurvOb.type === 'Callout' || this.activeSurvOb.type === 'OMT') return true
			}
			return false
		},
		showBauerMusicHistory() {
			if(this.activeSurvOb) {
				if (this.activeSurvOb.client === 'BauerPlace' && this.activeSurvOb.type === 'Callout' ) return true
			}
			return false
		},
		showTabBar() {
			if(this.activeSurvOb) {
				if(this.showMusicTabs || this.showBauerMusicHistory) return true
			}
			return false
		},
		uAuthStr() {
			return 'wlsesstoken='+this.store.wlsesstoken+'&uid='+this.store.user.id;
		},
		needShowCatiReports() {
			if(this.activeSurvOb) {
				if(this.activeSurvOb.type === 'RegRatings' || this.activeSurvOb.type === 'CATI' || ['SCA_Reg','SCA_CATI'].includes(this.activeSurvOb.client)) {
					if(this.store.user.superUser === 1 || this.store.user.perms_siteAdmin === 1 || this.store.user.perms.CATISuper === 1) return true
				}
			}
			return false
		},
		analystReportId() {
			if(this.activeSurvOb && this.activeSurvOb.type === 'Callout') {
				if(this.activeSurvOb.client === 'KIISSyd') return 1
				else if(this.activeSurvOb.client === 'MixMEL') return 3
				else if(this.activeSurvOb.client === 'MixADL') return 4
				else if(this.activeSurvOb.client === '973FM') return 5
				else if(this.activeSurvOb.client === '96FM') return 6
				else if(this.activeSurvOb.client === 'BauerAbsolute') return 7
				else if(this.activeSurvOb.client === 'BauerMagic') return 8
				else if(this.activeSurvOb.client === 'BauerPlace') return 9
				else if(this.activeSurvOb.client === 'BauerCountry') return 10
				else if(this.activeSurvOb.client === 'CADA') return 11
				else if(this.activeSurvOb.client === 'KissUk') return 12
				else if(this.activeSurvOb.client === 'KIISNet') return 13
			}
			return null
		},
		isMusicSurvey() {
			if(this.activeSurvOb) {
				if(this.activeSurvOb.type === 'Callout' || this.activeSurvOb.type === 'OMT') return true
			}
			return false
		},
		isRegRatingsSurvey() {
			if(this.activeSurvOb) {
				if(this.activeSurvOb.type === 'RegRatings') return true
			}
			return false
		},
		hookResults() {
			if(this.chosenWeightingChoice === true) return this.hookResultsWeighted
			else return this.hookResultsUnweighted
		},
		qgroupdata() {
			let results = []
			let qgroupquestions = []
			if(this.activeQgroupkey) {
				qgroupquestions = this.activeSurvOb.questions.filter(item => item.qgroupkey === this.activeQgroupkey)
			}
			else { //handle matrices
				let myind = this.activeSurvOb.questions.findIndex(item => item.qid === this.activeQuestionSingle.qid)
				let r = this.getStartAndEndOfMatrix(myind)
				if(r && r[0] && r[1] && r[0] < r[1]) {
					for(let i=r[0]; i<=r[1]; i++) {
						qgroupquestions.push(this.activeSurvOb.questions[i])
					}
				}
			}
			for(let q of qgroupquestions) {
				let qid = q.qid
				let res = this.resultsDataForDemo.find(item => item.qid === qid)
				if(res) results.push(res)
			}
			return results
		},
		qgroupopts() {
			return this.activeQuestionSingle.options
		},
		qgrouptitle() {
			if(this.activeQuestionSingle) {
				let qtext = this.activeQuestionSingle.qtext_stripped
				if(qtext.includes("|")) {
					let qarr = qtext.split("|")
					return qarr[0]
				}
				let allques = this.activeSurvOb.questions.filter(item => item.qgroupkey === this.activeQgroupkey)
				for(let aq of allques) {
					if(aq.qtext_stripped.includes("|")) {
						let qarr = aq.qtext_stripped.split("|")
						return qarr[0]
					}
				}
			}
			return 'Matrix / Grid Results'
		},
	},
	methods: {
		closeValidationView() {
			this.showGSValidator = false
		},
		toggleFilterView() {
			if(this.showFilterPopup === false) this.showFilterPopup = true
			else this.showFilterPopup = false
		},
		getStartAndEndOfMatrix(qind) {
			let firstInd = null
			let lastInd = null
			let myq = this.activeSurvOb.questions[qind]
			if(myq) {
				if(['MatrixHeadRadio','MatrixSubRadio','MatrixEndRadio','MatrixHeadCheck','MatrixSubCheck','MatrixEndCheck'].includes(myq.type)) {
					if (['MatrixHeadRadio', 'MatrixHeadCheck'].includes(myq.type)) {
						firstInd = qind
						lastInd = this.getEndMatrixQ(qind)
					}
					else if (['MatrixEndRadio', 'MatrixEndCheck'].includes(myq.type)) {
						firstInd = this.getHeadMatrixQ(qind)
						lastInd = qind
					}
					else if(['MatrixSubRadio','MatrixSubCheck'].includes(myq.type)) {
						firstInd = this.getHeadMatrixQ(qind)
						lastInd = this.getEndMatrixQ(qind)
					}
				}
			}
			return [firstInd, lastInd]
		},
		getEndMatrixQ(qind) {
			if(this.activeSurvOb.questions[qind+1]) {
				if(['MatrixEndRadio','MatrixEndCheck'].includes(this.activeSurvOb.questions[qind+1].type)) return qind+1
				else return this.getEndMatrixQ(qind+1)
			}
		},
		getHeadMatrixQ(qind) {
			if(this.activeSurvOb.questions[qind-1]) {
				if(['MatrixHeadRadio','MatrixHeadCheck'].includes(this.activeSurvOb.questions[qind-1].type)) return qind-1
				else return this.getHeadMatrixQ(qind-1)
			}
		},
		showMatrixGrid(qob) {
			this.activeQuestionSingle = qob
			this.activeQgroupkey = qob.qgroupkey
			this.showMatrixGridPopup = true
		},
		toggleView(viewtype) {
			this.pageViewType = viewtype
			this.setInitialSingleQuestion()
		},
		getMatrixVal(qresultSet, optid) {
			let res = qresultSet.results.find(item => item.key === optid)
			if(res) {
				if(this.percentSampleBase === 'alldemo') return res.pctSumWeightsOfAllDemo + '%'
				if(this.percentSampleBase === 'totalsurvey') return res.pctSumWeightsOfTotalSample + '%'
				else return res.pctSumWeightsOfAnswered + '%'
			}
			return ''
		},
		getMatrixSplitCase(qresultSet) {
			let qtext = qresultSet.title
			if(qtext.includes("|")) {
				let qarr = qtext.split("|")
				return qarr[1]
			}
			return qtext
		},
		navigateSingleQuestion(delta) { //delta will generally be +1 or -1
			let ind = this.activeSurvOb.questions.findIndex(item => item.qid === this.activeQuestionSingle.qid)
			if(ind >= 0) {
				if(delta < 0 && ind + delta >= 0) {
					this.activeQuestionSingle = this.activeSurvOb.questions[ind+delta]
				}
				else if(delta > 0 && ind + delta <= this.activeSurvOb.questions.length-1) {
					this.activeQuestionSingle = this.activeSurvOb.questions[ind+delta]
				}
			}
		},
		setSingleViewActiveQuestion(qob) {
			this.activeQuestionSingle = this.activeSurvOb.questions.find(item => item.qid === qob.qid)
		},
		showHookHistory(hookid) {
			this.chosenHookIdForHistoryChart = hookid
			this.activetab = 'musicHistoryChart'
		},
		getHookHistoryResults() {
			let targ = this.store.urlroot + "central/post-vue/dashboard/run-song-report"
			if(this.activeSurvOb.hooklistId !== null && parseInt(this.activeSurvOb.hooklistId) > 0) {
				let sendob = {
					hooklist: parseInt(this.activeSurvOb.hooklistId),
					hooklistSurvId: this.activeSurvOb.id,
					demo: this.chosenDemoId,
					stat: 'popindex',
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if (ret.surveys) this.hookHistorySurveys = ret.surveys
					if (ret.hookResults) this.hookHistoryResults = ret.hookResults
				}.bind(this))
			}
		},
		finishSaveDemo(newDemoId) {
			console.log('New Demo ID is: '+newDemoId)
			this.showDemoCreator = false
			this.getDemosForSurvey()
		},
		getPrepData() {
			let targ = this.store.urlroot + "central/post-vue/dashboard/get-initial-data"
			let sendob = {}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.surveys) this.userSurveyList = ret.surveys
			}.bind(this))
		},
		getDemosForSurvey() {
			let targ = this.store.urlroot + "central/post-vue/dashboard/get-demos-for-survey"
			let sendob = {
				survid: this.chosenSurveyId,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.demoListForSurvey) this.demoListRaw = ret.demoListForSurvey
			}.bind(this))
		},
		prepFilters() {
			if(this.filterDemos.length === 0) {
				if(this.activeSurvOb.genderq && this.activeSurvOb.genderq > 0) {
					let q = this.activeSurvOb.questions.find(item => item.qid === this.activeSurvOb.genderq)
					if(q) {
						this.filterDemos.push({qid: q.qid, qtrendkey: q.trendkey, selections: [], qob: q})
					}
				}
				if(this.activeSurvOb.ageq && this.activeSurvOb.ageq > 0) {
					let q = this.activeSurvOb.questions.find(item => item.qid === this.activeSurvOb.ageq)
					if(q && q.type === 'Radio') {
						this.filterDemos.push({qid: q.qid, qtrendkey: q.trendkey, selections: [], qob: q})
					}
					else { //explicit age from Select or Number entry
						this.filterDemos.push({qid: q.qid, qtrendkey: q.trendkey, selections: [], qob: q, isExplicitAge: true})
					}
				}
			}
			//add any defaults
			if(this.defaultFiltersForSurvey.length > 0) {
				for(let f of this.defaultFiltersForSurvey) {
					let fob = this.filterDemos.find(item => item.qtrendkey === f.qtrendkey)
					if(!fob) fob = this.filterDemos.find(item => item.qid === f.qid)
					if(fob) { //the filter from the server is already shown in our list
						if(f.selections.length > 0 && fob.selections.length === 0) {
							//only overwrite selections if current page has no selections, and server does have some
							fob.selections = f.selections
						}
					}
					else { //we need to add the default filter from the server to our page display
						let myqob = this.activeSurvOb.questions.find(item => item.qid === f.qid)
						if(!myqob) myqob = this.activeSurvOb.questions.find(item => item.trendkey === f.qtrendkey)
						if(myqob) {
							this.filterDemos.push({qid: myqob.qid, qtrendkey: myqob.trendkey, selections: f.selections, qob: myqob})
							myqob = null
						}
					}
					fob = null
				}
			}
			//now remove any filters by trendkey that don't exist in this survey
			for(let f of this.filterDemos) {
				if(f.qtrendkey && f.qtrendkey.length > 0) {
					let q = this.activeSurvOb.questions.find(item => item.trendkey === f.qtrendkey)
					if(!q) {
						let ind = this.filterDemos.findIndex(item => item.qtrendkey === f.qtrendkey)
						if(ind >= 0) this.filterDemos.splice(ind, 1)
					}
				}
			}
		},
		addFilterWithQuestion(qob) {
			let f = this.filterDemos.find(item => item.qid === qob.qid)
			if(!f) {
				// this.newQAddedToFilters = true //to stop watch from refetching data
				this.filterDemos.push({qid: qob.qid, qtrendkey: qob.trendkey, selections: [], qob: qob})
			}
			this.showFilterPopup = true
		},
		removeFilter(ind) {
			this.filterDemos.splice(ind, 1)
		},
		getDashboardData(isForTrend = false, trendkey = null, trendsurvid = null) {
			let survid = this.chosenSurveyId
			if(isForTrend === false) {
				this.store.showLoader = true
				this.hideTrendedDataPush = true
				setTimeout(function() {
					this.hideTrendedDataPush = false
					this.trendDataForQ = {}
				}.bind(this), 500)
			}
			else { //use passed trend survid
				survid = trendsurvid
			}
			let targ = this.store.urlroot + "central/post-vue/dashboard/get-dashboard-data"
			let mydem = this.demoList.find(item => item.id === this.chosenDemoId)
			let demdashString = mydem.sql //explicit SQL passed from demos-for-dash (not a database-saved demo)
			let filts = []
			for(let f of this.filterDemos) {
				filts.push({qid: f.qid, qtrendkey: f.qtrendkey, selections: f.selections})
			}
			let sendob = {
				survid: survid,
				demoid: this.chosenDemoId, //integer if db saved, 'dd_' prepended if demos-for-dash
				demdashString: demdashString,
				completes: this.chosenCompletesChoice,
				weighted: this.chosenWeightingChoice,
				filters: filts,
				trendkeyq: trendkey,
				filterInfDialRadioListenersOnly: this.filterInfDialRadioListenersOnly,
			}
			return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(isForTrend === true) {
					if(ret.results) return ret.results
					else return false
				}
				this.weightingIsAvailableForSurvey = ret.weightingAvailable
				if(ret.survey) this.activeSurvOb = ret.survey
				if(ret.completionData) this.completionData = ret.completionData
				if(ret.results) this.results = ret.results
				if(ret.hookResultsWeighted) this.hookResultsWeighted = ret.hookResultsWeighted
				if(ret.hookResultsUnweighted) this.hookResultsUnweighted = ret.hookResultsUnweighted
				if(ret.defaultFilters) this.defaultFiltersForSurvey = ret.defaultFilters
				this.prepFilters()
				if(this.isMusicSurvey === true && this.store.user.perms.panelUser !== 1) this.getHookHistoryResults()
				this.store.showLoader = false
				this.getTrendSurveys()
			}.bind(this))
		},
		getTrendSurveys() { //get the surveys that we need to look at for trend data
			if(this.activeSurvOb.surveyGroups && this.activeSurvOb.surveyGroups.length > 0) {
				let maxTrendAllowed = 10
				let mySurveyGroup = this.activeSurvOb.surveyGroups[0]
				let targ = this.store.urlroot + "central/post-vue/dashboard/get-trend-surveygroup"
				let sendob = {
					survid: this.chosenSurveyId,
					surveyGroup: mySurveyGroup,
					maxItems: maxTrendAllowed,
				}
				this.simplePost(targ, sendob).then(function (ret) {
					this.trendSurveys = ret['trendsurvs'];
				}.bind(this))
			}
		},
		getTrendData(trendkey) {
			this.trendDataForQ = {
				trendkey: trendkey,
				results: [],
			}
			for(let ts of this.trendSurveys) {
				let tsid = ts.survid
				this.getDashboardData(true, trendkey, tsid).then(function (ret) {
					if(ret) {
						let mydat = []
						if(ret[0]['data'] && ret[0]['data'][0] && ret[0]['data'][0]['results']) {
							mydat = ret[0]['data'][0]['results']
						}
						this.trendDataForQ.results.push({
							survid: tsid,
							survname: ts.survname_internal,
							resultdate: ts.resultdate,
							data: mydat
						})
					}
				}.bind(this))
			}
		},
		demoCreate() {
			this.showDemoCreator = true
		},
		showAssignQs() {
			this.showAssignQsPopup = true
		},
		report(rpttype, suid) {
			if(["formds","songlist","playlist","summ_scores"].includes(rpttype)) {
				let mydemo = this.demoList.find(item => item.id === this.chosenDemoId)
				let weightval = 0
				if(this.chosenWeightingChoice === true) weightval = 1
				window.location = this.store.urlroot+"central/post-vue/dashboard/get-music-report?survey="+suid+"&demo="+this.chosenDemoId+"&completes="+this.chosenCompletesChoice+"&report="+rpttype+"&demoNameText="+mydemo.name+'&weightVar='+weightval+'&'+this.uAuthStr
			}
			else if(rpttype === "textresp") {
				window.location = this.store.urlroot+"central/gets/export-text-answers?survey="+suid+"&demo="+this.chosenDemoId+"&completes="+this.chosenCompletesChoice+'&'+this.uAuthStr
			}
			else {
				window.location = this.store.urlroot+'central/post-vue/reports/create-csv-report?survid='+suid+'&demo=0&completes='+this.chosenCompletesChoice+'&report='+rpttype+'&'+this.uAuthStr
			}
		},
		createCsv() { //createcsv/percep summary
			this.isExportingToExcel = true
			let oldviewtype = this.pageViewType
			this.pageViewType = 'standard'
			setTimeout(function() {
				let content = document.querySelector("#excel-basic-wrapper")
				let contentT = content.cloneNode(true)
				contentT.innerHTML = contentT.innerHTML.replace(/,/g,''); //replaces , globally
				TableToExcel.convert(contentT, {
					name: "Perceptuals.xlsx",
					sheet: {
						name: 'Summary'
					}
				});
				this.isExportingToExcel = false
				this.pageViewType = oldviewtype
			}.bind(this), 100)
		},
		bauerSample() {
			window.location = this.store.urlroot + 'central/gets/bauer-sampling-report?survid='+this.chosenSurveyId+'&'+this.uAuthStr
		},
		runWeightReport() {
			window.location = this.store.urlroot + 'central/gets/weighting-report?survid='+this.chosenSurveyId+'&'+this.uAuthStr
		},
		getAnalystSummary() { //the song table export that replaces the old Analyst Excel/PDF export
			if(this.analystReportId > 0) {
				let wtsumval = ''
				if(this.chosenWeightingChoice === true) wtsumval = '&weightVar=1'
				window.location = this.store.urlroot + 'central/post-vue/reports/report-engine-entry?survid='+this.chosenSurveyId+'&rid='+this.analystReportId+'&publisher=1'+wtsumval+'&'+this.uAuthStr
			}
		},
		getGselCSV() { //csv hook scores for GSelector
			window.location = this.store.urlroot + 'central/post-vue/reports/create-gsel-output?surveyId='+this.chosenSurveyId+'&'+this.uAuthStr
		},
		getGoldStd() {
			window.location = this.store.urlroot + 'central/gets/create-gold-std-csv?getsurv='+this.chosenSurveyId+'&'+this.uAuthStr
		},
		getGoldStdJsonl() {
			window.location = this.store.urlroot + 'central/gets/create-gold-std-jsonl?getsurv='+this.chosenSurveyId+'&'+this.uAuthStr
		},
		getRFJsonl() {
			window.location = this.store.urlroot + 'central/gets/create-gold-std-rfjsonl?getsurv='+this.chosenSurveyId+'&'+this.uAuthStr
		},
		genCommentsExport() {
			// window.location = this.store.urlroot + 'central/post/cati-functions?getter=rrComments&survid='+this.chosenSurveyId+'&'+this.uAuthStr
			window.location = this.store.urlroot + 'central/gets/rr-comments-report?survid='+this.chosenSurveyId+'&'+this.uAuthStr
		},
		audologyExport() {
			window.open("https://yourwavelength.com/surveys/admin2/export-freq-survey.php?dataloc="+this.chosenSurveyId, '_blank');
		},
		setInitialSingleQuestion() {
			if(this.pageViewType === 'singlequestion' && this.activeSurvOb && !this.activeQuestionSingle) {
				this.activeQuestionSingle = this.activeSurvOb.questions[0]
			}
		},
		getMusicEraBreakdown() {
			let temps = JSON.parse(JSON.stringify(this.hookResults))
			temps.sort((a,b) => (b.popindex > a.popindex) ? 1 : ((a.popindex > b.popindex) ? -1 : 0))
			let top200 = temps.slice(0, this.musicErasTopN)
			let tempres = {
				'60s': 0,
				'70s': 0,
				'80s': 0,
				'90s': 0,
				'00s': 0,
				'10s': 0,
				'20s': 0,
				'Other': 0,
			}
			let counter = 0
			for(let s of top200) {
				let syear = parseInt(s.year)
				if(syear < 1960) tempres['Other']++
				else if(syear < 1970) tempres['60s']++
				else if(syear < 1980) tempres['70s']++
				else if(syear < 1990) tempres['80s']++
				else if(syear < 2000) tempres['90s']++
				else if(syear < 2010) tempres['00s']++
				else if(syear < 2020) tempres['10s']++
				else tempres['20s']++
				counter++
			}
			let myout = []
			if(counter > 0) {
				for(let era in tempres) {
					myout.push({era: era, pct: Math.round((tempres[era] / counter) * 100)})
				}
			}
			this.musicEraResults = myout
		},
	},
	watch: {
		chosenSurveyId() {
			if(this.chosenSurveyId > 0) {
				//this.filterDemos = [] //keep relevant filters during switching surveys
				this.$router.push('/dashboard/'+this.chosenSurveyId)
				if(this.showBauerMusicHistory === false && this.activetab === 'bauerHistory') this.activetab = 'perceptuals'
				if(this.store.user.perms.panelUser === 1 && this.activetab !== 'perceptuals') this.activetab = 'perceptuals'
				if(this.showMusicTabs === false && this.activetab !== 'perceptuals') this.activetab = 'perceptuals'
				this.getDemosForSurvey() //get the specific demos available for this survey
				this.chosenDemoId = 0 //reset to Total Demo and refresh dashboard data
				this.getDashboardData(false)
			}
		},
		chosenDemoId() {
			if(this.chosenSurveyId > 0) this.getDashboardData(false)
		},
		chosenCompletesChoice() {
			if(this.chosenSurveyId > 0) this.getDashboardData(false)
		},
		weightingIsAvailableForSurvey() {
			if(this.weightingIsAvailableForSurvey === false) this.chosenWeightingChoice = false
		},
		activeSurvOb() {
			if(this.activeSurvOb && this.pageViewType === 'singlequestion') {
				this.setInitialSingleQuestion()
			}
		},
		activetab() {
			if(this.activetab === 'musicEras') {
				this.getMusicEraBreakdown()
			}
		},
		musicErasTopN() {
			this.getMusicEraBreakdown()
		},
	},
	mounted() {

	},
	created() {
		document.title = "Results Dashboard";
		this.store.activeLmenuLink = 'dashboard';
		this.getPrepData()
		let urlparams = this.$route.params
		if(urlparams.surveyid !== undefined) {
			this.chosenSurveyId = parseInt(urlparams.surveyid)
		}
	}
}
</script>

<style scoped>
.selections-section {
	font-size: 13px;
	font-family: Roboto,'Arial';
	font-weight: bold;
	line-height: 1.4;
	background-color: #f1f2f6;
	padding: 30px 60px 20px;
	text-align: left;
}
.selections-group {
	display: flex;
}
.selections-group .wl-input-select {
	width: 250px;
}
.selections-group .wl-input-select.narrow {
	width: 150px;
}
.completion-info {
	font-size: 13px;
	font-family: 'Roboto', Arial;
	display: flex;
	justify-content: space-between;
	margin: 15px 0 0 5px;
	font-weight: normal;
}
.results-section {
	margin: 0;
	text-align: left;
	font-size: 13px;
	padding: 25px 60px;
}
.toggle-option {

}
.toggle-option span {
	display: flex;
	align-items: center;
}
.toggle-option input {
	margin: 0;
	padding: 0;
}
.toggle-option label {
	font-size: 13px;
	margin-left: 5px;
	padding-top: 2px;
}
.filters-holder {
	width: 500px;
	position: fixed;
	top: 100px;
	right: 50px;
	background: #FFFFFF;
	max-height: calc(100vh - 240px);
	overflow-y: auto;
	box-shadow: 0 0 20px 5px rgba(0,0,0,0.1);
	border: 1px solid #DDD;
}
.filter-topbar {
	background: #f1f2f6;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 40px);
	padding: 0 20px;
	display: flex;
	align-items: center;
	height: 40px;
	justify-content: space-between;
	border-bottom: 1px solid #DDD;
}
.filter-h1 {
	font-size: 15px;
	font-weight: bold;
}
.filter-topbar i {
	cursor: pointer;
	font-size: 16px;
}
.filter-heading {
	font-weight: bold;
	margin: 10px 0 10px;
}
.filter-heading i {
	cursor: pointer;
}
.filter-inside {
	padding: 20px 20px;
	margin-top: 30px;
	max-height: 500px;
	overflow-y: auto;
	background-color: #FFF;
}
.filter-inside .wl-input-text {
	border: 1px solid #CCC;
}
.filter-item {
	margin-bottom: 20px;
}
.filter-options {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
.filter-option label {
	display: flex;
	align-items: center;
}
.qnavbtn {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 38px;
	background: #0f75a5;
	border-radius: 5px;
	font-size: 15px;
	color: #FFF;
}
.eratable {
	margin-top: 20px;
}
.eratable tr td {
	padding: 5px 10px;
}
.eratable {
	border-collapse: collapse;
	border-top: 1px solid #DDD;
	border-left: 1px solid #DDD;
}
.eratable tr {
	border-bottom: 1px solid #DDD;
}
.eratable tr td {
	border-right: 1px solid #DDD;
}
</style>