<template>
	<BlanketOverlayWithPopup title="Media ID Mappings" width="1000" :hastabs="false" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="contained-popup-content">
				<p class="bold subheading" v-if="mappedhooks">{{mappedhooks.length}} hooks were mapped successfully (from your previous selections).</p>
				<br/>
				<p class="bold subheading">The following {{listWithSuggestions.length}} hooks have suggested maps ({{listWithoutSuggestions.length}} without)</p>
				<br/>
				<div v-for="sug in listWithSuggestions" :key="sug.mediaId" class="suggester">
					<p class="incoming bold">{{sug.artist}} - {{sug.title}} [{{sug.mediaId}}]</p>
					<p class="suggestItem" v-for="(suggestion, ind) in sug.suggestions" :key="ind">
						{{suggestion.artist}} - {{suggestion.title}} [{{suggestion.id}}]
						<span class="playbtn" @click.stop="play(suggestion.id)">Play</span>
						<span class="acceptbtn" @click.stop="saveMapping(suggestion.id, sug.mediaId)">Accept</span>
					</p>
				</div>
				<br/>
				<p class="bold subheading">The following are not assigned yet (copy and paste this list for searching)</p>
				<br/>
				<p v-for="(mi, ind) in missing" :key="ind">{{mi.artist}} - {{mi.title}} [{{mi.mediaId}}]</p>
			</div>
		</template>
		<template v-slot:buttons>
			<div style="display: flex; align-items: center">
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
				<audio src="" controls="" id="player" style="margin-left: 30px;"></audio>
			</div>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "HookMapToMediaId",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		chosenstationname: String,
		mappedhooks: Array,
		suggestedhooks: Array,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			completedMediaIds: [],
		}
	},
	computed: {
		listWithSuggestions() {
			let arr = this.suggestedhooks.filter(item => item.suggestions.length > 0)
			arr = arr.filter(item => !this.completedMediaIds.includes(item.mediaId))
			return arr
		},
		listWithoutSuggestions() {
			return this.suggestedhooks.filter(item => item.suggestions.length === 0)
		},
		missing() {
			let arr = this.listWithSuggestions
			arr = arr.concat(this.listWithoutSuggestions)
			return arr
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		play(hookId) {
			let a = document.getElementById('player')
			a.src = "https://yourwavelength.com/hooklib/" + hookId + ".mp3"
			a.play()
		},
		saveMapping(hookId, mediaId) {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/hooks/assign-media-map"
			let sendob = {
				hookId: hookId,
				mediaId: mediaId,
				station: this.chosenstationname
			}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				this.completedMediaIds.push(mediaId)
				this.$emit('addhook', [hookId, mediaId])
			}.bind(this))
		},
	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
.contained-popup-content {
	line-height: 1.2;
}
.subheading {
	font-size: 16px;
}
.incoming {
	font-size: 14px;
}
.playbtn, .acceptbtn {
	display: inline-block;
	padding: 5px 10px;
	background: #2BAEF6;
	color: #FFF;
	cursor: pointer;
	margin: 5px 0 5px 10px;
	transition: background 0.3s ease-in-out;
}
.playbtn:hover, .acceptbtn:hover {
	background: #1B8EEF;
}
.suggester {
	margin-bottom: 10px;
}
.suggestItem {
	margin-left: 20px;
}
</style>